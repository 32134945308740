<template>
  <div class="lg:text-xl about-us home relative text-base">    
    <a href="#" class="fixed bottom-0 right-0 z-50 mb-4 mr-4 bg-gray-800 border border-white rounded-full">
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="48" height="48">
        <path fill="none" d="M0 0h24v24H0z" />
        <path d="M12 10.828l-4.95 4.95-1.414-1.414L12 8l6.364 6.364-1.414 1.414z" fill="rgba(255,255,255,1)" />
      </svg>
    </a>
    <SiteHeaderBH />
    <div class="spacer lg:pt-0 pt-8"></div>
    <section class="lg:pt-24 lg:bg-cover bg-sub-banner pt-12 text-gray-800 bg-center">
      <div class="max-w-screen-xl px-4 py-16 mx-auto">
        <h2 class="lg:font-thin lg:text-6xl text-4xl font-light">Tentang Kami</h2>
        <p class="lg:text-base text-sm">Beranda > <span class="text-sitePurple font-bold">Tentang Kami</span></p>
      </div>
    </section>
    <div class="flex w-full">
      <div class="bg-siteOPurple w-1/3 py-2"></div>
      <div class="bg-sitePPurple w-1/3 py-2"></div>
      <div class="bg-sitePurple w-1/3 py-2"></div>
    </div>
    <section class="text-gray-800 bg-gray-200">
      <div class="lg:px-24">
        <div class="lg:px-24 md:pt-16 md:pb-12 lg:pt-20 lg:pb-16 pt-12 pb-8 mx-auto">
          <div class="lg:-mx-4 lg:items-center flex flex-wrap overflow-hidden">
            <div class="w-full px-4 overflow-hidden">
              <h4 class="lg:text-2xl lg:mb-6 text-sitePurple mb-4 text-xl font-bold">Informasi Klinik</h4>
              <img class="w-full mb-8" src="images/image-about-01.jpg">
              <p class="mb-4">
                Dipimpin oleh Direktur Medis Dr Marco Faria Correa, Bedah Plastik Dr Marco adalah klinik internasional
                yang memiliki hubungan erat dengan Singapura dan Brasil. Terletak di Mount Elizabeth Novena, kantor
                pusat di Singapura didekorasi dengan selera tinggi dan dirancang agar hangat, mengundang, dan nyaman
                bagi semua pasien lokal dan asing.
              </p>
              <p class="mb-4">
                Dr Marco memiliki reputasi terbaik di lebih dari 23 rumah sakit di seluruh dunia, dan berpengalaman
                dalam melakukan makeover dan prosedur lainnya di beberapa rumah sakit terbaik di Singapura.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="text-gray-800">
      <div class="md:pt-16 md:pb-12 lg:pt-20 lg:pb-16 max-w-screen-xl pt-12 pb-8 mx-auto">
        <div class="lg:-mx-4 lg:items-center flex flex-wrap overflow-hidden">
          <div class="md:w-1/2 w-full px-4 overflow-hidden">
            <img class="mb-4" src="images/image-doctor.jpg">
          </div>
          <div class="md:w-1/2 w-full px-4 overflow-hidden">
            <div class="lg:pl-8">
              <h4 class="lg:text-2xl lg:mb-6 text-sitePurple mb-4 text-xl font-bold">Informasi Dokter</h4>
              <p class="mb-4">
                Ahli Bedah Plastik Dr Marco Faria Correa adalah ahli bedah berpengalaman yang telah menghabiskan 35
                tahun terakhir bekerja menangani pasien dari semua kelompok etnis dan kebangsaan, secara mendalam
                memahami jenis tubuh yang berbeda dan kebutuhan pasien internasional.
              </p>
              <p class="mb-4">
                Dengan penekanan kuat pada keselamatan dan pengalaman pasien, Dr Marco hanya melakukan perawatan di
                rumah sakit berperalatan lengkap yang memenuhi standarnya.
              </p>
              <p class="mb-8">
                Dr Marco lulus dari Fakultas Kedokteran di Universitas Federal Rio Grande do Sul di Brasil pada tahun
                1978 dan memperoleh sertifikasi dan pengakuan profesional dalam Bedah Plastik pada tahun 1984.
              </p>
            </div>
          </div>
          <div class="w-full px-4 overflow-hidden">
            <h4 class="lg:text-2xl lg:mt-8 lg:mb-6 text-sitePurple mb-4 text-xl font-bold">Kisah Dari Singapura</h4>
            <p class="mb-4">
              Setelah bertahun-tahun berlatih dan mengasah keterampilannya, Dr Marco pertama kali datang ke Singapura
              pada tahun 1996 untuk melakukan demonstrasi bedah dan lokakarya untuk mendidik para dokter tentang teknik
              endoskopi abdominoplasti. Kemudian pada tahun 2000, ia diundang oleh Asosiasi Bedah Plastik Singapura
              untuk mempresentasikan tekniknya dalam “Perbaikan Sedot Lemak dengan pasien dalam Posisi Berdiri” serta
              memberikan pembaruan tentang “Bedah Plastik Endoskopi”.
            </p>
            <p class="mb-4">
              Ketika Kementerian Kesehatan meluncurkan program “Hunting for Talents”, Dr Marco diundang untuk bekerja di
              Singapura untuk menarik lebih banyak pasien internasional dan berkontribusi untuk mengubah Singapura
              menjadi Pusat Bedah Plastik & Kosmetik.
            </p>
            <p class="mb-4">
              Setelah itu, Dr Marco memperoleh Sertifikat Pendaftaran Medis bersyarat dari Singapore Medical Council
              pada tahun 2000 dan pada Maret 2002, ia dianugerahi Sertifikat Akreditasi Spesialis oleh Kementerian
              Kesehatan, Singapura. Sebulan kemudian, ia menerima Sertifikat Pendaftaran Spesialis Bedah Plastik. Pada
              tahun 2005, Dr Marco memperoleh pendaftaran lengkapnya di Singapore Medical Council dan mendapatkan
              pengakuan sebagai anggota penuh komunitas medis.
            </p>
            <p class="mb-8">
              Dr Marco yakin dalam mengutamakan pasien dan telah memperoleh izin operasi di rumah sakit di seluruh
              Singapura untuk memberikan perawatan dan kenyamanan menyeluruh bagi semua pasien. Dengan pengakuan dari
              rumah sakit di lebih dari 23 rumah sakit di seluruh dunia, Dr Marco berpengalaman dalam melakukan makeover
              dan prosedur berdampak lainnya di beberapa rumah sakit terbaik di Singapura.
            </p>
          </div>
        </div>
        <div class="lg:mt-8 lg:-mx-4 lg:items-center flex flex-wrap overflow-hidden">
          <div class="lg:w-1/3 w-1/2 px-4 overflow-hidden">
            <img class="mx-auto mb-8" src="images/logo-01.jpg">
          </div>
          <div class="lg:w-1/3 w-1/2 px-4 overflow-hidden">
            <img class="mx-auto mb-8" src="images/logo-02.jpg">
          </div>
          <div class="lg:w-1/3 w-1/2 px-4 overflow-hidden">
            <img class="mx-auto mb-8" src="images/logo-03.jpg">
          </div>
          <div class="lg:w-1/3 w-1/2 px-4 overflow-hidden">
            <img class="mx-auto mb-8" src="images/logo-04.jpg">
          </div>
          <div class="lg:w-1/3 w-1/2 px-4 overflow-hidden">
            <img class="mx-auto mb-8" src="images/logo-05.jpg">
          </div>
          <div class="lg:w-1/3 w-1/2 px-4 overflow-hidden">
            <img class="mx-auto mb-8" src="images/logo-06.jpg">
          </div>
          <div class="lg:w-1/3 w-1/2 px-4 overflow-hidden">
            <img class="mx-auto mb-8" src="images/logo-07.jpg">
          </div>
          <div class="lg:w-1/3 w-1/2 px-4 overflow-hidden">
            <img class="mx-auto mb-8" src="images/logo-08.jpg">
          </div>
          <div class="lg:w-1/3 w-1/2 px-4 overflow-hidden">
            <img class="mx-auto mb-8" src="images/logo-09.jpg">
          </div>
          <div class="lg:w-1/3 w-1/2 px-4 overflow-hidden">
            <img class="mx-auto mb-8" src="images/logo-15.jpg">
          </div>
        </div>
      </div>
    </section>
    <section class="text-gray-800">
      <div class="md:pb-12 lg:pb-16 max-w-screen-xl pb-8 mx-auto">
        <div class="lg:-mx-4 lg:items-center flex flex-wrap overflow-hidden">
          <div class="w-full px-4 overflow-hidden">
            <h4 class="lg:text-2xl lg:mt-8 lg:mb-6 text-sitePurple mb-4 text-xl font-bold">Penghargaan & Warisan</h4>
            <p class="mb-4">
              Selama bertahun-tahun, beliau telah memenangkan beberapa penghargaan internasional untuk karyanya dalam
              meningkatkan keamanan Endoskopi Abdominoplasti dan Bedah Payudara. Karena itu, beliau sering diundang
              untuk melakukan demonstrasi bedah dan untuk memberikan pengetahuannya tentang teknik-teknik yang
              ditingkatkan ini. Untuk berbagi pengetahuannya kepada generasi ahli bedah masa depan, Dr Marco juga telah
              menerbitkan banyak jurnal dan bab buku yang berkaitan dengan bidang bedah plastik & kosmetik.
            </p>
            <p class="mb-4">
              Dr Marco telah mengumpulkan banyak penghargaan atas kontribusinya di bidang ini, beberapa di antaranya
              termasuk:
            </p>
            <ul class="pl-5 mb-4 list-disc">
              <li class="mb-1">1996: Video Terbaik “Walter Scott Brown Award” dipresentasikan pada Pertemuan Tahunan
                American Society for Aesthetic Plastic Surgery tahun 1995</li>
              <li class="mb-1">1996: “Awad Dr. J.J.Legrand” “Prêmio Dr. J. J. Legrand” Dianugerahkan oleh Perhimpunan
                Kedokteran Estetika Brasil Kongres Kedokteran Estetika Panamerican Pertama</li>
              <li class="mb-1">1998: “Masumi Inaba, MD’s Crowned Prize” Dianugerahkan oleh Japan Society of Cosmetic
                Surgeons Kongres ke-74 Japan Society of Aesthetic Surgery Tokyo, Jepang</li>
              <li class="mb-1">2000: “A Prize for Excellence and Devotion to Cosmetic Surgery” Dianugerahkan oleh
                International Society of Aesthetic Surgery Kongres Dunia ke-3 ISAS Kongres JSAS ke-78 Kongres ke-2 JSLS
                Tokyo, Jepang</li>
              <li class="mb-1">2001: “Leader of Cosmetic Surgery in Millennium” Dianugerahkan oleh Federation of
                Restorative & Cosmetic Surgery of India di The World Congress on Aesthetic and Restorative Surgery VIII
                International pada Konferensi Medis Multifakultas Internasional Mumbai, India</li>
              <li class="mb-1">2009: “MOST OUTSTANDING INTERNATIONAL LEADER IN LIPOSUCTION AND COSMETIC SURGERY”
                Diberikan oleh komite ilmiah dari Kongres Dunia pertama tentang “Advances in Body Contouring” dari
                Philippines Society of Liposuction Surgery , Inc bekerja sama dengan Philippine Society for Cosmetic
                Surgery Philippine Academy of Dermatologic Surgery Foundation, Inc dan Philippine Academy of Aesthetic
                Surgery</li>
              <li>2016: Penghargaan Pengakuan atas Kontribusi pada Bedah Plastik yang membawa teknik Mini Abdominoplasti
                ke tingkat berikutnya dari bedah minimal lubang kunci dengan memperkenalkan penggunaan metode endoskopi
                dan robot untuk penggandaan rektus “ Robotic Abdominoplasty” oleh Philippine Society of Liposuction
                Surgery Inc, Akademi Filipina of Aesthetic Surgery, Philippine Society of Dermatologic Surgery
                Foundation Inc, Pan Pacific Aesthetic Institute Foundation Inc, Perhimpunan Filipina untuk Bedah
                Kosmetik Inc dan Asiana Pacific Academy of Cosmetic Surgery Inc.</li>
            </ul>
            <p class="mb-8">
              Saat ini, Dr Marco selalu mencari metode yang lebih aman dan inovatif yang dapat meningkatkan hasil
              sekaligus mengutamakan perawatan pasien.
            </p>
            <p class="mb-8">
              Lihat CV Dr Marco –  <a href="images/CV-updated-October-2017.pdf" target="_blank"
                class="text-sitePurple font-bold">CV diperbarui Oktober 2017</a>
            </p>
            <h4 class="lg:text-2xl lg:mb-6 text-sitePurple mb-4 text-xl font-bold">Keanggotaan Profesional</h4>
            <p class="mb-4">
              Menjadi anggota terhormat dari komunitas bedah plastik, Dr Marco adalah anggota dari Perhimpunan
              Profesional berikut :
            </p>
            <p class="mb-2 text-lg font-bold">Internasional:</p>
            <ul class="pl-5 mb-4 list-disc">
              <li class="mb-1">International Society of Aesthetic Plastic Surgery/ ISAPS</li>
              <li class="mb-1">American Society of Plastic Surgeons ASPS</li>
              <li class="mb-1">Asia Pacific Hernia Society</li>
            </ul>
            <p class="mb-2 text-lg font-bold">Brazil:</p>
            <ul class="pl-5 mb-4 list-disc">
              <li class="mb-1">Anggota Pendiri Chapter of Endoscopic Plastic Surgery of the Brazilian Society of Plastic
                Surgery</li>
              <li class="mb-1">Pendiri & Anggota Chapter of Rhinology of the Brazilian Society of Plastic Surgery</li>
              <li class="mb-1">Perhimpunan Bedah Plastik Brasil</li>
              <li class="mb-1">Perhimpunan Bedah Mikro Rekonstruksi Brasil</li>
              <li class="mb-1">Anggota Brazilian College of Surgeon Singapore</li>
            </ul>
            <p class="mb-2 text-lg font-bold">Singapura:</p>
            <ul class="pl-5 mb-4 list-disc">
              <li class="mb-1">Singapore Association of Plastic Surgeons / SAPS</li>
              <li class="mb-1">Singapore Society for Cosmetic (Aesthetic) Surgeons</li>
            </ul>
          </div>
        </div>
        <div class="lg:mt-8 lg:-mx-4 lg:items-center flex flex-wrap overflow-hidden">
          <div class="md:w-1/3 lg:w-1/5 w-1/2 px-4 overflow-hidden">
            <img class="mx-auto mb-8" src="images/logo-10.jpg">
          </div>
          <div class="md:w-1/3 lg:w-1/5 w-1/2 px-4 overflow-hidden">
            <img class="mx-auto mb-8" src="images/logo-11.jpg">
          </div>
          <div class="md:w-1/3 lg:w-1/5 w-1/2 px-4 overflow-hidden">
            <img class="mx-auto mb-8" src="images/logo-12.jpg">
          </div>
          <div class="md:w-1/3 lg:w-1/5 w-1/2 px-4 overflow-hidden">
            <img class="mx-auto mb-8" src="images/logo-13.jpg">
          </div>
          <div class="md:w-1/3 lg:w-1/5 w-1/2 px-4 overflow-hidden">
            <img class="mx-auto mb-8" src="images/logo-14.jpg">
          </div>
        </div>
      </div>
    </section>
    <div class="flex w-full">
      <div class="bg-siteOPurple w-1/3 py-2"></div>
      <div class="bg-sitePPurple w-1/3 py-2"></div>
      <div class="bg-sitePurple w-1/3 py-2"></div>
    </div>
    <SiteFormBH />
    <SiteFooterBH />
  </div>
</template>

<script>
  import SiteHeaderBH from '@/components/SiteHeaderBH.vue'
  import SiteFormBH from '@/components/SiteFormBH.vue'
  import SiteFooterBH from '@/components/SiteFooterBH.vue'
  export default {
    components: {
      SiteHeaderBH,
      SiteFormBH,
      SiteFooterBH
    },
    mounted() {  
      document.title = "Dr Marco Faria Correa Plastic Surgery - Operasi Plastik";  
    },
    data() {
      return {
        index: 0,
      }
    }
  }
</script>

<style>
  .bg-sub-banner {
    background-image: url(/images/bg-sub-banner.jpg);
  }
</style>